import React from "react"
import Meta from "../components/Meta"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import PodcastEpisode from "../components/PodcastEpisode"

const Template = ({ data, location }) => {
  const episode = data.prismicPodcastEpisode.data

  return (
    <Layout
      location={location}
      prismicLayout={data.prismicLayout}
      prismicSiteBanner={data.prismicSitebanner}>
      <Meta
        title={`${episode.episode_title.text} | Make it Work Podcast`}
        description={episode.episode_description.text}
        canonical={location.href}
      />
      <PodcastEpisode episode={episode} />
    </Layout>
  )
}

export default Template

export const postQuery = graphql`
  query ($id: String!) {
    ...PrismicSiteBanner
    ...PrismicLayout
    prismicPodcastEpisode(id: { eq: $id }) {
      ...PodcastEpisode
    }
  }
`

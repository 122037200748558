import React, { Fragment } from "react"
import PageHeader from "../PageHeader"
import Container from "../common/Container"
import RichText from "../RichText"
import styled from "styled-components"

const Frame = styled.div`
  font-size: 0.8em;
  iframe {
    margin: 3rem 0;
  }
`

const Transcript = styled.div`
  font-size: 0.8em;
  margin-bottom: 3rem;
`

const PodcastEpisode = ({ episode }) => {
  return (
    <Container readable>
      <PageHeader headerText={<h1>{episode.episode_title.text}</h1>}>
        <em>
          Season {episode.season_number}: Episode {episode.episode_number}
        </em>
      </PageHeader>

      <RichText render={episode.episode_description.richText} />

      <Frame>
        <div dangerouslySetInnerHTML={{ __html: episode.episode_embed_url }} />
      </Frame>

      {episode?.episode_transcript?.richText?.length > 0 && (
        <Transcript>
          <h2>Transcript</h2>
          <RichText render={episode.episode_transcript.richText} />
        </Transcript>
      )}
    </Container>
  )
}

export default PodcastEpisode